import { Link } from 'react-scroll'


function MenuList({ onClick }){
    const handlerClick = (e) => {
        if('function'===typeof onClick){
            onClick(e.target)
        }
    }
    return (
        // don't assign this childrens to any parent div, boxes, containers, etc.
        <>
        <Link  offset={-70} to="GetInTouch" spy={true} smooth={true} duration={800}>
            <div onClick={handlerClick} className="header-menu-items">Get in Touch</div>
        </Link>
        <Link onClick={handlerClick} offset={-70} to="TechnologywePrefer" spy={true} smooth={true} duration={1000}>
            <div className="header-menu-items">Technology we Prefer</div>
        </Link>
        <Link onClick={handlerClick} offset={-70} to="contact" spy={true} smooth={true} duration={800}>
            <div className="header-menu-items">Contact</div>
        </Link>
        </>
    )
}

export default MenuList;