import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import { Box, useMediaQuery} from '@mui/material';
import TechnologyUsed from "./components/body/TechnologyUsed";
import ContactUsForm from "./components/body/ContactUsForm";
import AOSContent from "./components/body/AOSContent";
import './App.css';
import BuildingIcon from './components/assets/icons/building.png'
import WorkIcon from './components/assets/icons/work.png'
import RocketIcon from './components/assets/icons/rocket.png'
import GroupHumansIcon from './components/assets/icons/group-humans.png'
import GradientIcon from './components/assets/icons/gradient-bg.jpg'
import DollarCoinIcon from './components/assets/icons/dollar-coin.png'
import GetYourDreamWebsiteIcon from './components/assets/icons/get-your-dream-website.jpeg'
import { Element } from 'react-scroll'
import Typewriter from 'typewriter-effect';
import { createTheme } from '@mui/material/styles'

const theme = createTheme();

function App() {
  const mobScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [typeWriterTextState, setTypeWriterTextState] = React.useState(1);


  return (
    <>
      <Header />

      <Box sx={{ mb: 2 }}>

        <Box sx={{
          backgroundImage: `url(${GetYourDreamWebsiteIcon})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
          width: '100vw',
          position: 'relative',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        >

          <div style={{ fontFamily:"'Zen Antique Soft', serif" ,color: '#fff', position: 'absolute', top: 60 }}>
            <h1 {...(!mobScreen ? { style:{fontSize:50}} : {} )}>
              {
                typeWriterTextState === 1 &&
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .typeString("Get your Dream Website.")
                      .pauseFor(2500)
                      .deleteAll()
                      .callFunction(() => {
                        setTypeWriterTextState(2)
                      })
                      .start();
                  }}
                  loop={true}
                />

              }
              {
                typeWriterTextState === 2 &&
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .typeString("Get <span style='color:red;'>Free</span> of Cost <span style='color:red;'> Demo !</span>")
                      .pauseFor(5000)
                      .deleteAll()
                      .callFunction(() => {
                        setTypeWriterTextState(1)
                      })
                      .start();
                  }}
                  loop={true}
                />
              }

            </h1>
          </div>

        </Box>

        <AOSContent text="Good Website is good business" imgPosition="left" img={BuildingIcon} />
        <AOSContent imgHeight={120} background="#e6f7ff" text={<span>The true <code>ENTREPRENEUR</code> is a risk taker, not an excuse maker</span>} imgPosition="right" img={GroupHumansIcon} />
        <div style={{ marginTop: 30 }}>
          <Element name="GetInTouch" >
            <ContactUsForm />
          </Element>
        </div>

        <div style={{
          backgroundImage: `url(${GradientIcon})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',

        }}>
          <AOSContent text="Building dreams one code at a time" imgPosition="right" img={WorkIcon} />
          <AOSContent text={<><span style={{ color: '#647ea6' }}>Low Price</span> <span style={{ color: '#26debe' }}>High Value</span></>} imgPosition="left" img={DollarCoinIcon} boxStyle={{ boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px' }} />
          <AOSContent text="Getting a quality website is not an expenses but rather an investment" imgPosition="right" img={RocketIcon} />
        </div>



        <div style={{ background: '#ecf5fe', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}>
          <Element name="TechnologywePrefer">
            <TechnologyUsed />
          </Element>
        </div>
      </Box>
      <Element name="contact">
        <Footer />
      </Element>
    </>
  );
}

export default App;
