import React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuList from './MenuList';

export default function MobileDrawer({ isOpen , onChange }) {

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    onChange(open)
   
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
      onClick={toggleDrawer(false)}
    >
      <List >
        {(MenuList({ onClick : ()=>onChange(false) }).props.children || []).map((children, index) =>
        {
        return(
          <ListItem button key={'menulist'+index}>
            <ListItemText primary={children}/>
          </ListItem>
        )
      })}
      </List>
     
    </Box>
  );


  return (
    <div>
          <SwipeableDrawer
            anchor='right'
            open={isOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            {list()}
            {/* <MenuList /> */}
          </SwipeableDrawer>
    </div>
  );
}