import { Grid, Paper } from '@mui/material';
import vue from './../assets/technologyUsed/vue.jpg';
import angular from './../assets/technologyUsed/angular.jpg';
import html5 from './../assets/technologyUsed/html5.jpg';
import java from './../assets/technologyUsed/java.png'
import node from './../assets/technologyUsed/node.jpg'
import php from './../assets/technologyUsed/php.jpg'
import react from './../assets/technologyUsed/react.jpg'
import SmallBGIcon from './../assets/icons/small-bg.png'

function TechnologyUsed() {
    const imgStyle = {
        width: '70px',
        margin : 15,
    }    

  return (
    <Paper sx={{ flexGrow: 1 , my: 5 , py : 2, mb:10, borderRadius : 2, bgcolor : 'transparent' ,textAlign:'center' ,
     backgroundImage: `url(${SmallBGIcon})`,
     
     backgroundRepeat: 'space',
     backgroundSize: 'cover'
     }}>
        <h2>Technology we Prefer</h2>
        <Grid container spacing={2} sx={{justifyContent:'center',alignItems:'center',mt:5}}>
            <Grid item>
                <img style={imgStyle} src={vue} alt="vue" />
            </Grid>
            <Grid item>
                <img style={imgStyle} src={html5} alt="html5" />
            </Grid>
            <Grid item>
                <img style={imgStyle} src={angular} alt="angular" />
            </Grid>
            <Grid item>
                <img style={imgStyle} src={java} alt="java" />
            </Grid>
            <Grid item>
                <img style={imgStyle} src={node} alt="node" />
            </Grid>
            <Grid item>
                <img style={imgStyle} src={php} alt="php" />
            </Grid>
            <Grid item>
                <img style={imgStyle} src={react} alt="react" />
            </Grid>
        
        </Grid>
    </Paper>
    );
}

export default TechnologyUsed;
