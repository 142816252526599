import React from "react";
import { Box, Divider } from "@mui/material"
import {Grid} from '@mui/material';
import { Email, LocationOn } from "@mui/icons-material";


function Footer() {
    const style = {
        position: "relative",
        left: 0,
        bottom: 0,
        width: "100%",
        minHeight: "10vh",
        backgroundColor: "#23506c",
        color: "#fff",
        textAlign: "left"
    }

  return (
    <Box style={style} sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} sx={{textAlign:'center',justifyContent:'center',alignItems:'center'}}>
            <Grid item xs={12} sm={12}>
                    <div className="detrator-logo">DETRATOR</div>
                    <Divider sx={{bgcolor:'#26debe',mt:3,maxWidth:'25%',mx:'auto'}} />
                    <Box style={{marginBottom:15,marginTop:10,display:'grid',justifyContent:'center',alignItems:'center'}}>
                        <div style={{alignItems:'left',display:'flex',marginTop:10}}><Email style={{marginRight:10}} /><a style={{color:'#fff'}} href="mailto:teamdetrator@gmail.com"> teamdetrator@gmail.com </a> </div>
                        <div style={{alignItems:'left',display:'flex',marginTop:10}}><LocationOn style={{marginRight:10}} /> Delhi, India  </div>
                    
                    </Box>
   
            </Grid>
            


           
        </Grid>

    </Box>
    );
}



export default Footer;  


