import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {Grid,Button} from '@mui/material';
import { Item } from '../Global/Item';
import TextField from '@mui/material/TextField';
import loginIcon from './../assets/icons/login.png'
import { useMediaQuery} from '@mui/material';
import { createTheme } from '@mui/material/styles'
import bgImage from './../assets/icons/login-bg.png'
import fire from '../Global/firebase.config'
import toast, { Toaster } from 'react-hot-toast';


const theme = createTheme();

export default function ContactUsForm() {
    const mobScreen = useMediaQuery(theme.breakpoints.down('md'));
    const style ={
        backgroundImage: `url(${bgImage})`,
        backgroundSize:'cover',
        backgroundOrigin : 'content-box',
        backgroundRepeat : 'no-repeat',
        backgroundPosition : 'inherit',
        borderRadius : 5,
        boxShadow: 'rgba(100, 100, 111, 0.2) 0 7px 29px 0'
    }

    const [users,setUsers] = useState({
        name :'',
        email : '',
        phNumber : '',
        msg : ''
    });

    const [isDisabledButton,setIsDisabledButton] = useState(true);

    const defaultInputError = {
        name : false,
        email : false,
        phNumber : false,
        msg : false
    }
    const [inputError,setInputError] = useState(defaultInputError)

    const handleInput = (data={}) => {
        setUsers({
            ...users,
            ...data
        })
    }

    function validateEmail(email) {
        var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const Tost = (msg,bg='#f44336') => {
        toast(msg,{
            duration: 8000,
            position : 'top-right',
            style : {
                backgroundColor : bg,
                color : '#fff',
            }
        })
    }

    const addUser = (e) => {
        e.preventDefault();
        var isNext = true;
        Object.keys(users).forEach(key => {
            if(key !== 'phNumber' && key !== 'msg' && isNext){
                if(!users[key]){
                    setInputError({
                        ...inputError,
                        [key] : true
                    })
                    isNext = false
                }
            }
        })

       
        if(isNext){
            setIsDisabledButton(true)
            setInputError(defaultInputError)
            if(validateEmail(users.email)){
                fire.firestore().collection(process.env.REACT_APP_COLLECTION_NAME).add({
                    name : users.name,
                    email : users.email,
                    phNumber : users.phNumber,
                    msg : users.msg
                }).then(() => {
                    Tost("Your form has been successfully submitted. we will contact you as soon as possible",'#4caf50')
                    setUsers({
                        name : '',
                        email : '',
                        phNumber : '',
                        msg : ''
                    })
                }).catch(err => { Tost('Somthing went wrong. Please try again Later');
                    setIsDisabledButton(false)
            })
            }else{
                Tost('Please enter valid email')
                setInputError({...inputError,email : true})
                setIsDisabledButton(false)
            }
        } 
       

            
    }

    useEffect(() => {
        if(users.name && users.email){
            if(isDisabledButton){
                setIsDisabledButton(false)
            }
        }else{
            if(!isDisabledButton){
                setIsDisabledButton(true)
            }
        }
    },[users,isDisabledButton])

    return (
        <>
            <Toaster />
            <Box sx={{ flexGrow: 1 }} style={style}>
                <Grid container spacing={2} sx={{justifyContent:'center',alignItems:'center'}}>
                    <Grid item xs={12} sm={12} lg={6} md={6} sx={{textAlign:'center'}}>    
                        <img src={loginIcon} alt="loginIcon" style={{width:mobScreen ? 300 : 500}} />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} md={6}>
                        <Item sx={{p:5,py:2,height:'100%',width:'100%',background:'transparent'}}>
                            <Box component="h3" sx={{textAlign:'left'}}>Get in Touch</Box>
                            <Box
                                component="form"
                                sx={{ '& > :not(style)': { my: 1, width: '80%' } }}
                                validate
                                autoComplete="off"
                                fullWidth 
                            >
                                
                                <div><TextField error={inputError.name} sx={{my:2}} fullWidth type="text" label="Full Name" variant="standard" onChange={e=>handleInput({name:e.target.value})} value={users.name} required /></div>
                                <div><TextField error={inputError.email} sx={{my:2}} fullWidth type="email" label="Email" variant="standard" onChange={e=>handleInput({email:e.target.value.toLowerCase()})} value={users.email} required /></div>
                                <div><TextField error={inputError.phNumber} sx={{my:2}} fullWidth type="number" label="Phone Number" variant="standard" onChange={e=>handleInput({phNumber:e.target.value})} value={users.phNumber} /></div>
                                <div><TextField error={inputError.msg} sx={{my:2}} fullWidth type="text" label="Type Your Message" variant="standard" value={users.msg} onChange={e=>handleInput({msg:e.target.value})} multiline /></div>
                            </Box>
                            <div style={{textAlign:'left'}}><Button sx={{my:3}} variant="contained" onClick={addUser} disabled={isDisabledButton}>Send</Button></div>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}