import { Grid } from "@mui/material";
import { Box , Divider , useMediaQuery } from "@mui/material";
import { createTheme } from '@mui/material/styles'

const theme = createTheme();

function AOSContent({ text , img , imgPosition='up' , imgHeight ,  background , boxStyle={} }) {
    const mobScreen = useMediaQuery(theme.breakpoints.down('md'));

    const imgStyle = {
        height: mobScreen ? imgHeight || 150 : 200,
    }
    return (
        <Box sx={{justifyContent:'center',borderRadius:3,textAlign:'center',my:5,py:3,...(background ? {bgcolor:background} : {})}} style={boxStyle} >
            <Grid container spacing={2} style={{alignItems:'center',textAlign:'center',justifyContent:'center'}}>
                <Grid item xs={12} sm={6} order={{ xs : 0 , sm : imgPosition==='left' ? 0 : imgPosition==='right' ? 1 : 0 }}>
                    <div data-aos={"fade-"+(imgPosition==='left'?'right':imgPosition==='right'?'left':imgPosition)}>
                        <img src={img} alt="icon" style={imgStyle} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}  style={{justifyContent:'center',textAlign:'center',cursor:'pointer',width:'100%'}}>
                    <div data-aos={"fade-"+imgPosition}>
                        <h1 className='QuotsText'>{text}</h1>
                        <Divider sx={{bgcolor:'#26debe',my:3,maxWidth:'60%',mx:'auto'}} />
                    </div>
                </Grid>
            </Grid>
        </Box>
    )
    
}

export default AOSContent;