import React from 'react'
import PropTypes from 'prop-types';

import { CssBaseline, useScrollTrigger, Toolbar, Box, AppBar, IconButton, Typography, useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles'
import MenuIcon from '@mui/icons-material/Menu';
import MobileDrawer from './MobileDrawer';
import MenuList from './MenuList';


function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const theme = createTheme();

export default function Header(props) {
    const mobScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    const [isOpen,setIsOpen] = React.useState(false) 

    const handleDrawerOpen = () => {
        setIsOpen(true);
    };

    return (
        <>
            <Box sx={{ display: 'flex' }} fix>
                <CssBaseline />
                <ElevationScroll {...props}>
                    <AppBar style={{ background: '#23506c' }}>
                        <Toolbar>
                            <Typography variant="h6" sx={{ flexGrow: 1 }} component="div">
                                <div className='detrator-logo'>
                                    DETRATOR
                                </div>
                            </Typography>
                            {
                                mobScreen &&
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={handleDrawerOpen}
                                >
                                    <MenuIcon />
                                </IconButton>
                            }
                            {
                                !mobScreen &&
                                    <Box style={{display:'inline-flex',justifyContent:'end',alignItems:'end'}}>
                                        <MenuList />
                                    </Box>

                            }
                        </Toolbar>
                    </AppBar>
                </ElevationScroll>
                <Toolbar />
            </Box>
            {
                mobScreen &&
                <MobileDrawer isOpen={isOpen} onChange={setIsOpen} />
            }

        </>
    )
}
